<template>
  <div v-if="isRemotePad">
    <queue-element-template
      :queue="remotePadQueue"
      :layout="layout"
    ></queue-element-template>
  </div>
  <div v-else>
    <queue-element-template
      v-for="(q, index) in history"
      v-bind:key="queue.deviceId + index"
      :queue="q"
      :style="historyElementPositionsStyle[index]"
      :layout="historyElementLayout[index]"
    ></queue-element-template>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import padStart from "lodash/padStart";
import cloneDeep from "lodash/cloneDeep";
import camelCase from "lodash/camelCase";
import remotecontrol from "@/utils/pusher";
import isPlainObject from "lodash/isPlainObject";

export default {
  name: "QueueElement",
  mixins: [layoutMixin],
  components: {
    // TextElement: () => import("@/components/core/TextElement"),
    QueueElementTemplate: () =>
      import("@/components/queuemanager/QueueElementTemplate"),
  },
  props: {
    queue: Object,
    settings: Object,
  },
  data() {
    return {
      num: 0,
      queueAudio: new Audio(),
      animation: "",
      animationTimer: null,
      history: [],
      remotePadQueue: {
        category: this.queue.category,
        number: "",
        seat: this.queue.seat,
        seatSign: this.queue.seatSign,
        animation: null,
        queueCustomized: this.queue.queueCustomized,
        queueNumberColor: this.queue.queueColor,
        queueNumberBackgroundColor: this.queue.queueBackgroundColor,
        queueSeatColor: this.queue.queueSeatColor,
        queueSeatBackgroundColor: this.queue.queueSeatBackgroundColor,
        queueServiceColor: this.queue.queueServiceColor,
        queueServiceBackgroundColor: this.queue.queueServiceBackgroundColor,
      },
    };
  },
  mounted() {
    remotecontrol.subscribe(this.queue.deviceId);
    remotecontrol.listen(this.queue.deviceId, "update", this.updateQueue);
    this.remotePadQueue.number = padStart(this.num, 3, "0");
  },
  destroyed() {
    remotecontrol.dispose(this.queue.deviceId);
  },
  computed: {
    isRemotePad: function() {
      return this.queue.device === 0;
    },
    historyElementLayout: function() {
      var layouts = [];
      //external device is always 1 column
      var cols = 1;
      var w = 100 / cols;
      var nQueue = 1;
      if (this.history.length > 0) {
        nQueue = this.history.length;
      }
      var h = 100 / Math.ceil(nQueue / cols);
      for (let i = 0; i < this.history.length; i++) {
        for (let j = 0; j < cols; j++) {
          var queueLayout = cloneDeep(this.layout);

          Object.keys(queueLayout).forEach((l) => {
            if (isPlainObject(queueLayout[l])) {
              queueLayout[l].zoneWidth = (queueLayout[l].zoneWidth * w) / 100;
              queueLayout[l].zoneHeight = (queueLayout[l].zoneHeight * h) / 100;
            }
          });

          layouts.push(queueLayout);
        }
      }

      return layouts;
    },
    historyElementPositionsStyle: function() {
      //external device is always 1 column
      var offset = 0.5;
      var cols = 1;
      var pos = [];
      var w = 100 / cols;
      var nQueue = 1;
      if (this.history.length > 0) {
        nQueue = this.history.length;
      }
      var rows = Math.ceil(nQueue / cols);
      var h = (100 - (rows - 1) * offset) / rows;
      for (let i = 0; i < this.history.length; i++) {
        for (let j = 0; j < cols; j++) {
          var x = j * w;
          var y = i * h + offset * i;
          pos.push({
            position: "absolute",
            left: `${x}%`,
            top: `${y}%`,
            width: `${w}%`,
            height: `${h}%`,
          });
        }
      }
      return pos;
    },
  },
  methods: {
    updateQueue: function(data) {
      if (this.animationTimer) {
        clearTimeout(this.animationTimer);
        this.animationTimer = null;

        setTimeout(() => {
          if (this.remotePadQueue) {
            this.remotePadQueue.animation = "";
          }
        }, 0);
      }

      if (this.isRemotePad) {
        this.remotePadQueue.number = padStart(Number(data.Number), 3, "0");
        this.queue.seat = data.Seat;
        this.queue.seatsign = data.SeatSign;

        setTimeout(() => {
          this.remotePadQueue.animation = "queue-animation";
        }, 0);

        this.animationTimer = setTimeout(() => {
          this.remotePadQueue.animation = "";
          this.animationTimer = null;
        }, 4000);

        this.$emit("update-queue", data);
      } else {
        // external device
        this.history = [];
        for (let i = 0; i < data.History.length; i++) {
          let elem = {};
          let keys = Object.keys(data.History[i]);
          for (let k = 0; k < keys.length; k++) {
            elem[camelCase(keys[k])] = data.History[i][keys[k]];
          }

          elem.category = elem.service;
          elem.queueCustomized = true;

          elem.queueServiceColor = elem.serviceColor;
          elem.queueServiceBackgroundColor = elem.serviceBackgroundColor;
          elem.queueSeatColor = elem.seatColor;
          elem.queueSeatBackgroundColor = elem.seatBackgroundColor;
          elem.queueNumberColor = elem.numberColor;
          elem.queueNumberBackgroundColor = elem.numberBackgroundColor;

          if (elem.lastCalled) {
            setTimeout(() => {
              elem.animation = "queue-animation";
              this.$set(this.history, i, elem);
            }, 100);

            this.animationTimer = setTimeout(() => {
              elem.animation = "";
              this.animationTimer = null;
              this.$set(this.history, i, elem);
            }, 4000);
          }

          this.$set(this.history, i, elem);

          if (elem.lastCalled) {
            this.$emit("play-audio", {
              Number: elem.number,
              Seat: elem.seat,
              SeatSign: elem.seatSign,
              Service: elem.service,
            });
          }
        }
      }
    },
  },
};
</script>
<style>
/* @keyframes queue-flash {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    opacity: 1;
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    opacity: 0;
  }
}

.queue-animation {
  animation: queue-flash 4s;
} */
</style>
